<template>
  <app-dropdown-filter
    v-model="model"
    icon="ph:translate"
    item-text="label"
    item-value="value"
    :items="items"
    :label="$t('course.language.filter.label')"
    :placeholder="$t('course.language.filter.placeholder')"
  >
    <template #selection>
      <span class="font-semibold">
        {{ selectionLabel }}
      </span>
    </template>
  </app-dropdown-filter>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  modelValue?: string;
}>();

const model = useVModel(properties, "modelValue");

const { t } = useI18n();
const items = [
  {
    label: t("course.language.filter.labels.fr"),
    value: "fr",
  },
  {
    label: t("course.language.filter.labels.en"),
    value: "en",
  },
];

const selectionLabel = computed(() => {
  if (model.value)
    return items.find((item) => item.value === model.value)?.label;

  return t("course.language.filter.labels.none");
});
</script>
