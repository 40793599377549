<template>
  <app-page-wrapper
    class="sticky-below-header !top-[--layout-offset-top] z-10 !px-2 md:!px-6"
  >
    <div
      class="flex items-center justify-between gap-4 rounded bg-white p-3 shadow-lg"
    >
      <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
        <tag-filter v-model="filters.tags" data-cy-tag-filter />

        <course-session-date-filter
          v-model:from="filters.from"
          v-model:to="filters.to"
          class="hidden md:block"
          data-cy-session-date-filter
          :default="defaultFilters"
        />

        <course-language-filter
          v-model="filters.lang"
          class="hidden md:block"
          data-cy-lang-filter
        />

        <course-format-filter
          v-model="filters.duration"
          class="hidden md:block"
        />
      </div>

      <client-only>
        <app-pop-transition>
          <app-button
            v-show="hasActiveFilters"
            class="hidden md:block"
            color="primary"
            variant="tonal"
            @click="reset"
          >
            {{ $t("search.page.filters.reset") }}
          </app-button>
        </app-pop-transition>
      </client-only>

      <div class="relative md:hidden">
        <app-icon-button
          :class="{ 'text-primary before:bg-primary-100': hasActiveFilters }"
          icon="ph:sliders"
          :label="$t('search.page.filters.open_dialog')"
          @click="toggleFilterDialog(true)"
        />
        <span
          v-show="hasActiveFilters"
          class="absolute -right-2 -top-2 grid h-4 w-4 place-content-center rounded-full bg-primary-900 text-xs font-semibold text-white"
          >{{ numberOfActiveFilters }}</span
        >
      </div>
    </div>

    <search-filters-dialog
      v-if="showFilterDialog"
      v-model="filters"
      v-model:open="showFilterDialog"
      :total-number-of-results="totalNumberOfResults"
    />
  </app-page-wrapper>
</template>

<script lang="ts" setup>
import { startOfTomorrow } from "date-fns";

import {
  type SearchPageFilters,
  useDefaultSearchFilters,
  useSearchPageFiltersActions,
} from "~/pages/catalog/index/search/_includes/composables/search-page-filters.data";

const SearchFiltersDialog = defineAsyncComponent(
  () => import("./search-page-filters-dialog.vue"),
);

const filters = defineModel<SearchPageFilters>({ required: true });

const defaultFilters: Partial<SearchPageFilters> = {
  from: startOfTomorrow().toISOString(),
};

defineProps<{
  totalNumberOfResults?: number;
}>();

const { hasActiveFilters, reset, numberOfActiveFilters } =
  useSearchPageFiltersActions(filters, {
    default: useDefaultSearchFilters(),
  });

const [showFilterDialog, toggleFilterDialog] = useToggle();
</script>
